<template>
  <div class="new-content">
    <kcTitle title="新闻资讯" en="News"></kcTitle>
    <transition name="el-zoom-in-center">
    <div class="new-list" v-if="showData.length > 0">
        <div v-for="item in showData.slice((pageNum - 1) * pageSize, pageNum * pageSize)" :key="item.id"
             class="list-cont">
          <div class="flex" style="padding-bottom: 50px">
            <div class="img" style="width: 15%">
              <img :src="item.imgUrl" width="100%">
            </div>
            <div class="new-list-title ml20 pointer" @click="goSrc(item.newsUrl)">
              <div class="new-list-title1">{{ item.title }}</div>
              <div class="new-list-title2 ellipsis">{{ item.info }}</div>
              <div class="new-list-title3">{{ item.releaseTime }}</div>
            </div>
          </div>
          <div class="split-line">
            <div class="split-line-item"></div>
          </div>
        </div>
      <div class="flex justify-content-center sheet" style="width: 100%;margin-bottom: 30px; margin-top: 50px">
        <div class="flex">
          <div class="flex justify-content-start align-items-center" >
            <img src="../../../assets/images/News/prev.png" @click="prev" width="50%"/>
          </div>
          <div class="flex justify-content-center align-items-end">
            <div class="out-pageNum ">
              <div :style="{transform: `translateY(${(pageNum-1)*-20}px)`}" class="flex flex-column num-content ">
                <div v-for="item in totalPageNum" :key="item" class="pageNum ">{{ getNum(item) }}</div>
              </div>
            </div>
            <div> / </div>
            <div class="totalPageNum">{{ getNum(totalPageNum) }}</div>
          </div>
          <div class="flex justify-content-end align-items-center" >
            <img src="../../../assets/images/News/next.png" @click="next" width="50%"/>
          </div>
        </div>
      </div>
    </div>
      </transition>
  </div>
</template>

<script>
import {getWebsiteNewsInfo} from "@/api";

export default {
  name: "newsList",
  data() {
    return {
      showData: [],
      pageSize: 6,
      pageNum: 1,
      totalPageNum: 0
    }
  },
  mounted() {
    this.getData()
    this.pageNum = "0" + this.pageNum
  },
  methods: {
    getData() {
      getWebsiteNewsInfo().then(res => {
        this.showData = res.data.data
        let num = 0
        if ((this.showData.length % this.pageSize) == 0) {
          num = parseInt(this.showData.length / this.pageSize)
        } else {
          num = parseInt(this.showData.length / this.pageSize) + 1
        }
        this.totalPageNum = num
      })
    },
    goSrc(src) {
      window.open(src, '_blank')
    },
    getNum(num) {
      let value = 0
      if (num < 10) {
        value = "0" + num
        return value
      } else {
        value = num
        return value
      }
    },
    prev() {
      if (this.pageNum != 1) {
        this.pageNum = parseInt(this.pageNum) - 1
      }
    },
    next() {
      if (this.pageNum != this.totalPageNum) {
        this.pageNum = parseInt(this.pageNum) + 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes boost {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.new-content {
  padding-top: 81px;
  min-width: 1200px;
  width: 1440px;

  .new-list {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 30px;

    .list-cont {
      padding: 50px 80px 0px 80px;

      &:hover {
        color: #18933B !important;

        img {
          animation: boost 0.8s;
          animation-fill-mode: forwards;
        }
      }
      &:hover > div > .split-line-item{
        width: 100%;
      }
      &:hover  > div > div > .new-list-title1 {
        color: #18933B ;
      }

      .new-list-title {
        .new-list-title1 {
          font-size: 20px;
          font-weight: bold;
          color: #000000;
          height: 30%;
          transition: all 0.3s;
        }

        .new-list-title2 {
          width: 1000px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 23px;
          height: calc(70% - 20px);
        }

        .new-list-title3 {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          height: 20px;
        }
      }
    }

    .sheet {
      .out-pageNum {
        height: 21px;
        width: 20px;
        overflow-y: hidden;

        .num-content {
          transition: all 0.7s;

          .pageNum {
            height: 20px;
            font-size: 16px;
            color: #333333;
            font-weight: bold;
          }
        }

      }

      .totalPageNum {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
.split-line {
  width: 100%;
  height: 2px;
  background: #D4D4D4;
  .split-line-item {
    width: 10%;
    height: 100%;
    background: #18933B;
    transition: all 0.3s;
  }
}
</style>
