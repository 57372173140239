<template>
  <div class="app-container">
    <div style="width: 100%">
      <img src="../../assets/images/News/newbgimg.png" width="100%">
    </div>
    <div class="flex justify-content-center">
      <newsList></newsList>
    </div>
    <div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import newsList from "@/views/News/component/newsList";
import bottom from "@/components/bottom";
export default {
  name: "index",
  components: {
    newsList,
    bottom
  }
}
</script>

<style lang="scss" scoped>
</style>
